import { render, staticRenderFns } from "./serviceItem.vue?vue&type=template&id=b830b598&scoped=true&"
import script from "./serviceItem.vue?vue&type=script&lang=js&"
export * from "./serviceItem.vue?vue&type=script&lang=js&"
import style0 from "./serviceItem.vue?vue&type=style&index=0&id=b830b598&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b830b598",
  null
  
)

export default component.exports