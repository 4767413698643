import request from '@/utils/request.js'
// =====================================================课程================================================
// 查询课程列表list
export const getCourseList = (query) => {
  return request({
    method: 'get',
    url: '/system/course/list',
    params: query
  })
}
// 通过课程id查询详情
export const getCourseDetail = (query) => {
  return request({
    method: 'get',
    url: '/system/course/queryList',
    params: query
  })
}
// 获取课程banner
export const bannerList = (query) => {
  return request({
    method: 'get',
    url: '/system/courseBar/list',
    params: query
  })
}
// 查询老师list
export const getTeacherList = (query) => {
  return request({
    method: 'get',
    url: '/system/teacher/list',
    params: query
  })
}
// 根据老师id查询老师详情和其绑定的咨询详情
export const detailTeacher = (query) => {
  return request({
    method: 'post',
    url: '/system/teacher/queryTeacherAndConsults',
    data: query
  })
}
export function fileVide0(data) {
  return request({
    url: '/system/file/video/' + data.courseId,
    method: 'get',
    // params:data
  })
}
// =====================================================咨询================================================
// 获取咨询轮播图
export function getbannerList() {
  return request({
    url: '/system/consultBar/list',
    method: 'get'
  })
}
// 查询咨询列表
export const getConsultList = (query) => {
  return request({
    method: 'get',
    url: '/system/consults/list',
    params: query
  })
}
// 通过id查询咨询详情
export function queryList(query) {
  return request({
    url: '/system/consults/queryList',
    method: 'get',
    params: query
  })
}


// 搜索首页
export function queryInfo(query) {
  return request({
    url: '/system/homepage/queryInfo',
    method: 'get',
    params: query
  })
}
//热门院校
export function queryCollegeBcuRank(query) {
  return request({
    url: '/system/college/queryCollegeBcuRank',
    method: 'get',
    params: query
  })
}

// 热门专业
export function majorhotList(query) {
  return request({
    url: '/system/majorhot/list',
    method: 'get',
    params: query
  })
}

// 热门咨询
export function hotconsultsList(query) {
  return request({
    url: '/system/hotconsults/list',
    method: 'get',
    params: query
  })
}
// 判断用户是否有权限观看 线上课程【视频】  /system/userOrder/permissions?userphone=手机号&courseId=课程ID
export function userHaspermissions(query) {
  return request({
    url: '/system/userOrder/permissions',
    method: 'get',
    params: query
  })
}
// 添加课程信息
export function addofflinecourse(query) {
  return request({
    url: '/system/userOrder/addofflinecourse',
    method: 'post',
    data: query
  })
}
